define("ember-cli-notifications/components/ecn-icon-success", ["exports", "@ember/component", "ember-cli-notifications/templates/components/ecn-icon-success"], function (_exports, _component, _ecnIconSuccess) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); } /* eslint-disable ember/no-classic-components */
  class EmberCliNotificationsIconSuccessComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "layout", _ecnIconSuccess.default);
      _defineProperty(this, "tagName", '');
    }
  }
  _exports.default = EmberCliNotificationsIconSuccessComponent;
});