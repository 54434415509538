define("ember-cli-notifications/services/notifications", ["exports", "@ember/service", "@ember/array", "@ember/object", "@ember/runloop", "ember-get-config"], function (_exports, _service, _array, _object, _runloop, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); } /* eslint-disable ember/no-classic-classes, ember/no-get */
  const globals = _emberGetConfig.default['ember-cli-notifications'] || {}; // Import app config object

  class NotificationsService extends _service.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "content", (0, _array.A)());
    }
    // Method for adding a notification
    addNotification(options) {
      // If no message is set, throw an error
      if (!options.message) {
        throw new Error('No notification message set');
      }
      const notification = _object.default.create({
        message: options.message,
        type: options.type || 'info',
        autoClear: options.autoClear ?? globals.autoClear ?? false,
        clearDuration: options.clearDuration ?? globals.clearDuration ?? 3200,
        onClick: options.onClick,
        htmlContent: options.htmlContent || false,
        cssClasses: options.cssClasses
      });
      this.content.pushObject(notification);
      if (notification.autoClear) {
        (0, _object.set)(notification, 'remaining', notification.clearDuration);
        this.setupAutoClear(notification);
      }
      return notification;
    }

    // Helper methods for each type of notification
    error(message, options) {
      return this.addNotification({
        ...options,
        message,
        type: 'error'
      });
    }
    success(message, options) {
      return this.addNotification({
        ...options,
        message,
        type: 'success'
      });
    }
    info(message, options) {
      return this.addNotification({
        ...options,
        message,
        type: 'info'
      });
    }
    warning(message, options) {
      return this.addNotification({
        ...options,
        message,
        type: 'warning'
      });
    }
    removeNotification(notification) {
      if (!notification) {
        return;
      }
      notification.set('dismiss', true);

      // Delay removal from DOM for dismissal animation
      (0, _runloop.later)(this, () => {
        this.content.removeObject(notification);
      }, 500);
    }
    setupAutoClear(notification) {
      notification.set('startTime', Date.now());
      const timer = (0, _runloop.later)(this, () => {
        // Hasn't been closed manually
        if (this.content.indexOf(notification) >= 0) {
          this.removeNotification(notification);
        }
      }, notification.remaining);
      (0, _object.set)(notification, 'timer', timer);
    }
    pauseAutoClear(notification) {
      (0, _runloop.cancel)(notification.timer);
      const elapsed = Date.now() - notification.startTime;
      const remaining = notification.clearDuration - elapsed;
      (0, _object.set)(notification, 'remaining', remaining);
    }
    clearAll() {
      this.content.forEach(notification => {
        this.removeNotification(notification);
      });
      return this;
    }
    setDefaultAutoClear(autoClear) {
      (0, _object.set)(globals, 'autoClear', autoClear);
    }
    setDefaultClearDuration(clearDuration) {
      (0, _object.set)(globals, 'clearDuration', clearDuration);
    }
  }
  _exports.default = NotificationsService;
});